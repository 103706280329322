import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';
import { composeWithDevTools } from "redux-devtools-extension";

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
const loggerMiddleware = createLogger();

export const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(
        thunkMiddleware,
        loggerMiddleware
    ))
);
export default store;