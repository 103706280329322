import React, { Component } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import Loadable from 'react-loadable';
import './App.scss';
import moment from 'moment';

const loading = () => <div className="animated fadeIn pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import('./views/Pages/Login'),
  loading
});

const Register = Loadable({
  loader: () => import('./views/Pages/Register'),
  loading
});

const MyApp = Loadable({
  loader: () => import('./views/Pages/Register'),
  loading
});

const BuyCoins = Loadable({
  loader: () => import('./views/Myclubs/BuyUserCoins'),
  loading
});
const clubPlanJoin = Loadable({
  loader: () => import('./views/Myclubs/clubPlanJoin'),
  loading
});

const shopBuy = Loadable({
  loader: () => import('./views/Myclubs/clubPlanJoin'),
  loading
});

const ThankYou = Loadable({
  loader: () => import('./views/Myclubs/ThankYou'),
  loading
});
const WebinarThankYou = Loadable({
  loader: () => import('./views/Myclubs/WebinarThankYou'),
  loading
});
const NewWebinarThankYou = Loadable({
  loader: () => import('./views/Myclubs/NewWebinarThankYou'),
  loading
});
const Page404 = Loadable({
  loader: () => import('./views/Pages/Page404'),
  loading
});

const Page500 = Loadable({
  loader: () => import('./views/Pages/Page500'),
  loading
});

const UserForm = Loadable({
  loader: () => import('./views/Pages/UserForm'),
  loading
});

const UserFormWithAI  = Loadable({
  loader: () => import('./views/Pages/UserFormWithAI/UserFormWithAI'),
  loading
});

const AIListings  = Loadable({
  loader: () => import('./views/Pages/AIListings/AIListings'),
  loading
});

const SignatureSpeech  = Loadable({
  loader: () => import('./views/Pages/SignatureSpeech/SignatureSpeech'),
  loading
});

const NewUserForm = Loadable({
  loader: () => import('./views/Pages/NewUserForm'),
  loading
});

const ViewPost = Loadable({
  loader: () => import('./views/Myclubs/ViewPost'),
  loading
});

const webinarPage = Loadable({
  loader: () => import('./views/Pages/Webinar'),
  loading
});

const NewWebinarPage = Loadable({
  loader: () => import('./views/Pages/NewWebinar'),
  loading
});

const InfluencerwebinarPage = Loadable({
  loader: () => import('./views/Pages/InfluencerWebinar'),
  loading
});

const BuyPlan = Loadable({
  loader: () => import('./views/Myclubs/BuyUserPlan'),
  loading
});

const userCardManagement = Loadable({
  loader: () => import('./views/Myclubs/UserCardManagement'),
  loading
});

const manageTrasactionDetails = Loadable({
  loader: () => import('./views/Myclubs/ManageTrasactionDetails'),
  loading
});

const manageGameQuestion = Loadable({
  loader: () => import('./views/Myclubs/ManageGameQuestions'),
  loading
});

const LinkedAccount = Loadable({
  loader: () => import('./views/Pages/LinkedAccount/LinkedAccount'),
  loading
});

function convertToDateForIE(dateString) {
  var str = dateString.replace(/^(.*-[0-9][0-9])([0-9][0-9]:.*$)/, '$1T$3')
  var dd = new Date(str).getTime();
  return dd;
}

function loggedIn() {
  try {

    var token = localStorage.getItem("jwt");
    var user = localStorage.getItem("user");
    if (token && user && typeof token != 'undefined') {
      var payloadArray = token.split(".");
      var payload = payloadArray[1];
      if (payload && typeof payload != 'undefined') {
        var dPayNonJSON = atob(payload);
        var dPayJSON = JSON.parse(dPayNonJSON);
        if (dPayJSON && typeof dPayJSON != 'undefined') {
          var eDate = dPayJSON.expire.date;
          if (eDate) {
           // var eDateTimeStamp1 = convertToDateForIE(eDate);
            var  eDateTimeStamp = moment(eDate).valueOf();
            var currentTimeStamp = new Date().getTime();

            if (currentTimeStamp < eDateTimeStamp) {
              return true;
           }
          }
        }
      }
    }
  } catch (e) {
      localStorage.removeItem("jwt");
    return false;
  }

   localStorage.removeItem("jwt");
  console.log('return false');
  return false;
}



const AfterLogin = ({ component: Component, ...rest }) => {
  // console.log('loggedIn()', loggedIn());
  return <Route {...rest} render={props => (
    loggedIn() ? (
      <Component {...props} />
    ) : (
      <Redirect to={{
        pathname: '/login',
        state: { from: props.location }
      }} />
    )
  )} />
}

const BeforeLogin = ({ component: Component, ...rest }) => {
  console.log('BL. loggedIn()', loggedIn());
  return <Route {...rest} render={props => (
    !loggedIn() ? (
      <Component {...props} />
    ) : (
      <Redirect to={{
        pathname: '/myclubs',
        state: { from: props.location }
      }} />
    )
  )} />
}

class App extends Component {

  render() {
    return (
      <HashRouter>
        <Switch>
          {/* new webinar  */}
          <BeforeLogin exact path="/influencer_webinar" name="Webinar Page" component={NewWebinarPage} />
          
          {/* new webinar */}
          <BeforeLogin exact path="/webinar" name="Webinar Page" component={webinarPage} />
          <BeforeLogin exact path="/webinar/influencer" name="Webinar Page" component={InfluencerwebinarPage} />
          <BeforeLogin exact path="/getmyapp" name="User Form" component={UserForm} />
          {/*  UserFormWithAI will be replaced with step form and drag and drop as in bellow NewUserForm  in future*/}
          <BeforeLogin exact path="/getmyapp-ai" name="User Form" component={UserFormWithAI} />
          <BeforeLogin exact path="/ai-listings" name="AI Listings" component={AIListings} />
          <BeforeLogin exact path="/signaturespeech" name="AI Listings" component={SignatureSpeech} />
          
          {/* new app builder screen with step form and drag and drop*/}
          <BeforeLogin exact path="/create_your_club" name="User Form" component={NewUserForm} />
          {/* new app builder screen */}
          <BeforeLogin exact path="/view/post/:clubId/:postId" name="View Post" component={ViewPost} />

          <BeforeLogin exact path="/login" name="Login Page" component={Login} />
          {/* <Route exact path="/login" name="Login Page" component={Login} /> */}
          <BeforeLogin exact path="/register" name="Register Page" component={Register} />

          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route exact path="/500" name="Page 500" component={Page500} />
          <Route exact path="/user/buy" name="Buy Coins" component={BuyCoins} />
          <Route exact path="/club/plan/join/:planId" name="Club Plan Join" component={clubPlanJoin} />
          <Route exact path="/thankyou/to/user" name="Thank You" component={ThankYou} />
          <Route exact path="/webinar/thankyou/to/user" name="Thank You" component={WebinarThankYou} />
          {/* New Webinar Thankyou page  */}
          <Route exact path="/influencer_webinar/thankyou/to/user" name="Thank You" component={NewWebinarThankYou} />
          {/* New Webinar Thankyou page  */}
          <Route exact path="/card/added" name="Thank You" component={ThankYou} />

          <Route exact path="/club/shop/buy/:shopId" name="Shop Buy" component={shopBuy} />

          <Route exact path="/user/card/:clubId" name="User Card Management" component={userCardManagement} />
          <Route exact path="/user/transactions/:clubId" name="User Transactions" component={manageTrasactionDetails} />

          <Route exact path="/club/game/manage/questions/:clubId/:gameId" name="Manage Question" component={manageGameQuestion} />

          <Route exact path="/user/plan" name="Buy Plan" component={BuyPlan} />

          <Route exact path="/third_party/link_account/connect" name="Third party link _accounts" component={LinkedAccount} />

          {/* <Route path="/" name="Home" component={DefaultLayout} /> */}
          <AfterLogin path="/" name="Home" component={DefaultLayout} />
        </Switch>
      </HashRouter>
    );
  }
}

export default App;
