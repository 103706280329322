export function events(state = { all_events: [], loading: true, event_start_date: "", event_information: [] }, action) {
    switch (action.type) {
        case 'GET_EVENT_SUCCESS':
            let events = [];
            if (action.data.data) {
                const eventItems = action.data.data.map((event) => (
                    events.push({ 'id': event['id'], 'start': event['event_start_date'], 'end': event['event_end_date'], 'title': event['name'], 'description': event['description'] })
                ))
                return {
                    ...state,
                    all_events: events,
                    loading: false,
                    event_information: action.data.data
                };
            }
        default:
            return state;
    }
}
